.gss-card {
    margin-bottom: 10px;
    padding: 5px 20px 20px 20px;
}

.gss-divider {
    margin-top: 15px;
    margin-bottom: 15px;
}

.gss-toolbar {
    height: 30px;
    margin-bottom: 10px;
}

.btn-tools {
    float: left;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 1px;
}

.custom-table {
    width: 100%;
}

.code-tree {
    background-color: #222222;
    border-radius: 5px;
    color: rgb(201, 164, 0);
}

.code-tree .bp3-tree-node-caret > svg {
    fill: red;
  }

.integration-header {
    float: left;
}

.integration-header-2 {
    font-size: 15px;
    margin-left: 30px;
    font-weight: bold;
}

.html-table {
    width: 100%;
}

.html-table-header {
    font-size: 12px;
    text-align: center;
    background-color: rgb(11, 27, 48);
}

.html-table-footer {
    background-color: #15112b;
}

.book-counter {
    text-align: center;
    font-size: 13px;
}

.drawer-book-name {
    font-size: 13px;
    padding-left: 5px;
    float: left;
}

.book-name-icon {
    color: rgba(172, 177, 172, 0.73);
}

.book-name-button {
    margin-top: 16px;
    padding: 20px 0;
    text-align: center;
    align-items: center;
}
  
.book-id {
    text-align: center;
}

.book-id2 {
    color: #8ABBFF !important;
    padding-top: 20px;
    padding-bottom: 8px;
    padding-right: 0;
    font-weight: 650;
}

.book-id-button {
    color: rgb(201, 164, 0);
    margin-top: 16px;
}

.delete-modal {
    background-color: rgb(255, 247, 247);
    border: 2px solid #ff20208f;
    border-radius: 4px;
}

.delete-modal-bookname {
    font-weight: bold;
    text-align: center;
}

.sync-modal-bookname {
    font-weight: bold;
    text-align: center;
}

.edit-modal {
    background-color: rgb(234, 232, 255);
    border: 2px solid #2027ff8f;
    border-radius: 4px;
}

.edit-retain-button {
    float: left;
}

.edit-retain-helper {
    float: left;
}

.book-tab {
    background-color: #383E47;
    color: rgb(201, 164, 0);
    width: 100%;
    padding: 4px;
    font-weight: 650;
}

.book-tabs {
    background-color: #383E47;
    color: rgb(201, 164, 0);
    width: 100%;
    font-size: 12px;
}

.book-status {
    font-size: large;
    font-weight: bold;
    text-align: center;
    margin: 0px;
}

.book-status-td {
    font-size: 12px;
}

.status-true {
    color: lightgreen;
}

.status-false {
    color: lightcoral;
}

.status-statustext {
    text-align: center;
    margin-top: 5px;
}

.action-td {
    margin-right: 5px;
    font-size: 12px;
    text-align: center;
}

.btn-actions {
    font-size: 12px;
}

.button1 {
    font-size: 12px;
}

.button2 {
    font-size: 12px;
}

.button3 {
    font-size: 12px;
}

.drawer-bookname-button {
    float: right;
    transform: scale(140%);
    padding-right: 5px;
    padding-bottom: 20px;
}

.drawer-bookid2 {
    color: rgb(201, 164, 0);
    font-weight: 650;
    display: inline-block;
}

.drawer-callout {
    margin-top: 10px;
    padding: 0;
    cursor: pointer;
}

.drawer-list {
    text-align: center;
    background-color: rgb(11, 27, 48);
    padding: 2%;
    margin-bottom: 2%;
}

.htmltable {
    width: 100%;
    font-size: 12px;
    /* background-color: #1c43779a; */
}

.drawer-list-table-header {
    font-size: 12px;
    background-color: rgb(21, 51, 90) !important;
}

.drawer-table-counter {
    text-align: center;
    padding-top: 3px;
    font-size: 12px;
}

.drawer-sheetname {
    font-size: 12px;
    padding-top: 3px;
    padding-left: 4%;
    padding-right: 4%;
}

.drawer-sheetname-button {
    float: right;
    transform: scale(90%);
}

.drawer-sheetid {
    text-align: center;
    padding-top: 3px;
    color: rgb(255, 127, 23);
    font-weight: 500;
    font-size: 12px;
}

.drawer-footer {
    /* background-color: #15112b; */
    background-color: rgb(11, 27, 48);
    padding: 10px;
    
}

.drawer-tr {
    background-color: #284074;
}
