* {
    box-sizing: border-box;
}

nav {
    float: left;
    width: 14%;
    height: 90vh;
    background: #383E47;
}

article {
    float: left;
    padding: 20px 30px 20px 30px;
    width: 86%;
    color: #FFFFFF;
    /* background-image: url('../../../public/BG-02\(2\).jpg'); */
    background-color: #27496A;
    background-position: center;
    background-size: cover;
    height: 90vh;
    overflow: auto;
}

section::after {
    content: "";
    display: table;
    clear: both;
}

footer {
  background-color: rgb(14, 31, 53);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  padding: 10px;
  text-align: center;
  color: #afbebfbc;
  border: 1px solid #3B3B4F;
  border-bottom: 0px;
}

html, body {
    height: 100%;
    padding: 0;
  }
  
  body {
    display: flex;
    flex-direction: column;
  }
  
  main {
    flex: 1;
    padding-bottom: 50px;
  }
  
  footer {
    height: 50px;
    justify-content: center;
    align-items: center;
  }
  