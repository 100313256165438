.div-header {
    height: 5%;
}

.button-group-header {
    width: 70%;     
}

.input-group-header {
    width: 30%;     
    margin-right: 1%;
}

.div-body {
    height: 65%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: .5%;
    display: flex;
    padding: 1%;
}

.div-body-a {
    width: 20%;
}

.body-a-header {
    padding-left: 25%;
    width: 88%;
}

.body-a-text {
    height: 65%;
    margin-right: 12%;
    margin-bottom: 5%;
    text-align: justify;
    font-style: italic;
    color: gray;
    font-size: medium;
}

.div-body-b {
    width: 80%;
}

.div-footer {
    height: 26%;
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    padding: 1%;
    padding-bottom: 0;
}

.body-b-header {
    padding-left: 0;
    width: 88%;
}

.div-footer-a {
    width: 20%;
}

.div-footer-b {
    width: 80%;
}

.dialog-container {
    width: 20%;
}

.dialog-body-container {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
}

.form-group-1 {
    width: 100%;
}

.radio-group-1 {
    margin-left: 3%;
}

.form-group-5 {
    width: 100%;
    margin-left: 3%;
}

.text-area {
    height: 3em;
    width:97%;
    resize: none;
}

.general-container {
    width: 100%;
    padding: 2%;
    display: flex;
    flex-wrap: wrap;
}

.acc-cards-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 74.5vh;
    justify-content: space-between;
    font-family: monospace;
}

.card1 {
    width: 64%;
    border-radius: 5px;
    background-color:#15112b62 !important;
    border: 10px ridge #ffffff2d;
}

.card2{
    width: 35%;
    border-radius: 5px;
}

.card2a {
    height: 77%;
    border-radius: 5px;
    /* background-image: url('https://mcdn.wallpapersafari.com/medium/41/59/FVaEvp.jpg');
    background-position: bottom;
    background-size: cover; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-color:#15112b62 !important;
    border: 10px ridge #ffffff2d;
}

.card2b {
    height:20%;
    margin-top: 3.5%;
    border-radius: 5px;
    background-color:#2e0e0e65 !important;
}

.account-header {
    margin-top: 1%;
    font-weight: 600;
    font-size: 20px;
}

.account-firstline {
    display: flex;
    width: 100%;
    margin-left: 3%;
}

.account-firstline-a {
    width: 55%;
    margin: 1%;
}

.account-firstline-b {
    width: 35%;
    margin: 1%;
}

.divider {
    margin-top: 10%;
}

.footer-container {
    display:inline;
}

.account-footer {
    margin-top: 3%;
    margin-bottom: 2%;
    font-weight: 600;
    font-size: 20px;
}

.account-footer1 {
    display: flex;
    width: 100%;
    margin-left: 3%;
    margin-top: 1%;
}

.account-footer1-a {
    width: 40%;
}

.profile-image-container {
    position:sticky;
    width: 220px;
    height: 220px;
    margin-top: 10%;
}

.image1 {
    border-radius: 50%;
    border: 10px solid #ffffff79;
    width: 220px;
    height: 220px;
}

.details-container {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fullname-container {
    font-size:xx-large;
}

.email-container {
    font-weight: 400;
    font-size: larger;
    text-align: center;
}

.tag-container {
    margin-top: 1%;
    text-align: center;
    width: 40%;
}

.user-password-container {
    margin-top: 5%;
    text-align: center;
    width: 100% !important;
    cursor: pointer;
}

.user-password-container2 {
    margin-top: 1%;
    text-align: center;
    width: 100% !important;
}

.all-users-card {
    margin: 2%;
    border-radius: 5px;
    background-color:#15112b6b !important;
    border: 10px ridge #ffffff2d;
    height: 74.5vh;
    max-height: 74.5vh;
}

.all-users-card-table{
    width: 100%;
    border: 2px solid #ffffff2d;
}

.all-user-thead {
    height: 4vh;
}

.all-user-th {
    font-size: 13px;
    background-color: rgb(11, 27, 48);
    top: 0;
    position: sticky;
    z-index: 1;
    text-align: center !important;
}

.all-user-tbody {
    font-family: monospace;
    font-size: 12px;
}

.all-user-tbody-email {
    text-align: center !important;
}

.all-user-tbody-name {
    font-weight: 500;
}

.all-user-tbody-others {
    text-align: center !important;
}