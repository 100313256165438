.wrapper-add-sheet {
    font-size: 12px !important;
}

.delete-sheets-sheetname {
    font-weight: bold;
    margin-top: 5%;
    margin-left: 20%;
}

.delete-sheets-button:hover {
    background-color:#8523232d !important;
    color: #FA999C !important;
}

.add-sheet-button {
    float: left;
}

.or-divider {
    border: 0px;
    font-size: 15px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.task-exit {
    float: left;
    margin-left: 5px;
}