h3 {
    color: rgb(204, 196, 196);
}

.tr-active-sheets {
    font-size: 12px;
    background-color: rgb(11, 27, 48);
}

.table-active-sheets {
    width: 40%;
    margin-top: 10px;
}

.manage-settings-title {
    font-size: 20px;
    font-weight: bold;
}

.settings-card-title {
    font-weight: bold;
}

.settings-card-description {
    margin-top: 5px;
    margin-bottom: 15px;
}

.danger {
    color: #F44336;
}

.manage-settings-card {
    margin-bottom: 30px;
}

.general {
    width: 60%;
}

.settings-danger-divider {
    margin-bottom: 15px;
}

.danger-card {
    border: 1px solid #E53935;
    background-color: #e5383517 !important;
}

.delete-workspace-reasons-ul li {
    line-height: 1.5;
}