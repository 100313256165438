.bp4-dark .bp4-menu {
    /* background-color: #13234e; */
    background-color: #0C243D;
    border-right: 1px ridge #01a7ba3b;
    text-decoration: none;
}

.bp4-menu-header {
    color: #afbebfbc;
    padding-top: 10px;
}

a:link {
    text-decoration: none;
    color: #FFFFFF;
}

.menu {
    padding: 10px;
    height: 100%;
    align-items: center;
    border-radius: 0px;
}

.menu-item-dashboard {
    margin-top: 10px;
    padding: 10px;
    background: #1ab6ea2d !important;
    color: #FFFFFF !important;
}

.menu-item-dashboard-active {
    margin-top: 10px;
    padding: 10px;
    background: #1ab6ea63 !important;
    color: #FFFFFF !important;
}

.menu-item-dashboard:hover {
    background-color: #0077B5;
    color: #FFFFFF !important;
    animation: horizontal-shake 1s 1;
}

.menu-item-dashboard-active:hover {
    margin-top: 10px;
    padding: 10px;
    background: #1ab6ea63 !important;
    color: #FFFFFF !important;
    animation: horizontal-shake 1s 1;
}

.sidebar-menu-item {
    padding: 7px;
    margin-top: 5px;
    color: #FFFFFF !important;
}

.sidebar-menu-item:hover {
    animation: horizontal-shake 1s 1;
}

@keyframes horizontal-shake {
    0% { transform: translateX(0) }
    20% { transform: translateX(10px) }
}

@keyframes vertical-shake {
    0% { transform: translateY(0) }
    20% { transform: translateY(-5px) }
}