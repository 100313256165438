.add-icon-color {
    color: #398bff !important;
}

.add-job-button:hover {
    background-color:#c68f542d !important;
    border-color: #C68E54 !important;
    color: #C68E54 !important;
}

.trigger-helper-text {
    color: grey;
}

.run-job-button:hover {
    background-color:#2385512d !important;
    border-color: #64AF8A !important;
    color: #64AF8A !important;
}

.run-job-label-container {
    margin-top: 6%;
}

.run-job-label {
    font-weight: bold;
}

.delete-job-button:hover {
    background-color:#8523232d !important;
    border-color: #FA999C !important;
    color: #FA999C !important;
}

.delete-job-label-container {
    margin-top: 6%;
}

.delete-job-label {
    font-weight: bold;
}