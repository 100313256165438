.add-icon-color {
    color: #398bff !important;
}

.add-sub-button:hover {
    background-color:#2385512d !important;
    border-color: #64AF8A !important;
    color: #64AF8A !important;
}

.task-helper-text {
    color: grey;
}

.delete-sub-button:hover {
    background-color:#8523232d !important;
    border-color: #FA999C !important;
    color: #FA999C !important;
}

.delete-sub-label {
    font-weight: bold;
    margin-top: 5%;
}