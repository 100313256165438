.bp4-navbar.bp4-dark, .bp4-dark .bp4-navbar {
    /* background-color: rgb(11, 27, 48); */
    background-color: #3877A3;
    box-shadow: 0px 5px 4px 0px rgba(37, 36, 36, 0.515);
}

.header {
    margin-bottom: 50px;
}

.navbar {
    border-radius: 0px;
}

.navbar-heading {
    font-size: 18px;
    font-weight: 500;

}

.image-banner-logo {
    width: 45px;
    height: 45px;
}

.image-banner {
    width: 110px;
    height: 50px;
}

.version-label {
    font-weight: 100;
    color: #ffffff48;
}