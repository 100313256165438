.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 30px 30px 30px;
}

.title { 
    color: #FFFFFF;
}

.description {
    color: #90A4AE;
}

.icon {
    color: #FFB74D;
}