.empty-sheet {
    color: rgb(99, 99, 99);
    text-align: center;
    background-color: #25273D;
    margin: 0;
    padding: 5px;
}

.card-sheet2 {
    margin-bottom: 25px;
    width: 100%;
    height: 69vh;
    position: relative;
}

.card-sheet2b {
    border: 1px solid grey;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.switch-toggle-sheet {
    float: left;
}

.card-container {
    display: flex;
    width: 100%;
    margin-bottom: -30px;
    flex-direction: column;
}

.btn-triggers {
    margin-left: 10px;
    height: 30px;
    position: relative;
    float: right;
}

.timer-span {
    font-size: 12px;
    font-weight: normal;
    float: right;
    margin-bottom: 5px;
    margin-right: 6px;
    float: left;
    color: rgb(112, 111, 111);
    cursor: progress;
}

.card-source {
    padding: 5px;
}

.bread-source {
    scale: 80%;
}

.task-progress-bar {
    height: 3px !important;
}

.thead-tr {
    font-size: 12px;
    background-color: rgb(11, 27, 48);
    top: 0;
    position: sticky;
    z-index: 1;
}

.thead-th {
    text-align: center !important;
}

.task-sheet-tools {
    font-size: 12px;
    width: 100%;
    display: inline;
    padding-bottom: 10px;
}

.task-sheet-name {
    width: 50%;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    float: left;
}

.spinner-cover {
    background-color: #1A237E;
    opacity: 0.3;
    color: white;
    height: 68.8vh;
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 1;
}

.trigger-spinner {
    opacity: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
}

.addsheet-button2 {
    display: flex;
    justify-content: right;
    margin-bottom: 10px;
}