.header-button {
    width: 100%;
    font-size: 13px;
}

.note-div {
    margin: 3%;
    color: slategrey;
}

.footer-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1%;
}

.delete-collection-button {
    width: 12%;
}  

.delete-collection-bookname {
    font-weight: bold;
    margin-top: 5%;
}