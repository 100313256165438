.bp4-card.bp4-dark, .bp4-dark .bp4-card {
	background-color: #2a2a4000;
}

.cards-container {
	display: flex;
	justify-content: center;
	height: 100%;
}

.card-a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 350px;
	height: 350px;
	margin-top: 250px;
	padding: 0;
	background-color: #ffffffc0 !important;
}

.card-b {
	padding: 0;
	width: 350px;
	height: 350px;
	margin-top: 250px;
	border: 10px solid #ffffffc0;
	/* background-color: #0232A1d8 !important; */
	background: rgb(2,50,161);
	background: linear-gradient(90deg, rgba(2,50,161,0.8) 0%, rgba(2,99,202,0.8) 90%);
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.image-banner {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.header-text {
	text-align: center;
	font-size: large;
	color: #9554CA;
	width: 100%;
	height: 20% !important;
	background-image: url('./MainBanner.jpg');
    background-position: bottom;
    background-size: cover;
}

.header-divider {
	margin-bottom: 20px;
}

.username-form1 {
	color: #7cfff2;
	border-top: 10px solid #ffffffc0;
	font-size: medium;
	align-items: center;
	width: 100%;
	padding-top: 8%;
}

.username-form2 {
	color: #7cfff2;
	font-size: medium;
	font-weight: 500;
	align-items: center;
	width: 100%;
}

.username-input {
	box-shadow: 2px 2px #ffffff80;
}

.submit-button {
	width: 90% !important;
	margin-top: 5%;
	margin-left: 4%;
	background-color: #00BEAA !important;
	box-shadow: 3px 3px #ffffff80 !important;
}