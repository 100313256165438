.card-terminal-workers {
    margin-bottom: 20px;
}

.terminal-htmltable {
    font-size: 12px !important;
    width: 50%;
}

.terminal-thead-tr {
    background-color:#0C1B30;
    top: 0;
    position: sticky;
    z-index: 1;
}

.terminal-action-buttons {
    height: 33px;
    width: 50%;
    margin-bottom: 5px;
}

.scheduler-htmltable {
    font-size: 12px !important;
    width: 80%;
}

.scheduler-thead-tr {
    background-color:#15112b;
    top: 0;
    position: sticky;
    z-index: 1;
}

.sched-mode {
    margin-bottom: 15px;
}

.sched-days {
    margin-bottom: 15px;
    font-size: 12px;
}

.sched-datepicker {
    padding: 5px;
    width: 166%;
    color: white;
}

.jobs-automation-spinner-cover {
    opacity: 0.2;
    display: flex;
    position: absolute;
}

.jobs-automation-spinner {
    height: 30%;
    width: 83%;
    position: absolute;
    z-index: 1;
}

.sched-title {
    font-weight: normal;
}

.sched-date-picker {
    padding: 5px;
    width: 80px;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
}