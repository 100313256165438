.container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
  
.page-header {
    margin: 0;
    font-family:Arial, Helvetica, sans-serif
}

.addsheet-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-top: 1%;
}

.addsheet-button2 {
    display: flex;
    justify-content: right;
    margin-bottom: 10px;
}

.card-wrapper {
    display: contents;
    width: 100%;
    height: 220px;
}

.card-sheet1 {
    margin-top: 1%;
    width: 200px;
    height: 200px;
    position: relative;
}

.card-sheet1b {
    background-color:#15112b;
    border: 1.5px solid #666666;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.card-sheet1b-header {
    font-size: 12px;
    padding: 2%;
    border-bottom: 1.5px solid #666666;
    display: flex;
    height: 14.4%;
    justify-content: space-between;
}

.card-sheet1b-switch {
    width: 100%;
    display: flex;
}

.card-sheet1-label {
    /* margin-right: 4%; */
    font-weight: bold;
}

.card-sheet1-progress {
    position: absolute;
    top: 17.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    font-size:xx-small;
}

.subfolder-div {
    padding: 5px;
    width: 195px;
    height: 170px;
    display: flex;
    text-align: center;
    overflow-y: auto !important;
    overflow-x: hidden;
}

.subfolder-htmlselect {
    font-size: 12px !important;
    text-align: center;
}

.subfolder-div-label {
    font-size: smaller;
    height: 100%;
    text-align: center;
    padding-top: 8px;
}

.subfolder-description {
    min-height: 32%;
    max-height: 32%;
    min-width: 98%;
    max-width: 98%;
    margin-left: 1%;
}

.card-action-buttons-div {
    color:royalblue;
    width: 100%;
}

.card-action-buttons {
    margin-top: 3%;
    margin-left: 5%;
    padding-left: 8px;
    width: 100%;
}

.html-table1 {
    width: 100%;
}
  
tbody .no-jobs td {
    text-align: center;
    vertical-align: middle;
}

.no-jobs {
    height: 7vh;
    display: table-row;
}

.no-jobs-div {
    color: gray;
}

.tr1 {
    font-family: monospace;
    font-size: 12px;
    height: 5px !important;
    border: 1px solid red;
}

.td-checkbox {
    padding-left: 10px;
    padding-top: 5px;
}

.check-hover:hover {
    background-color:#42ff9a2d !important;
    border-color: #64AF8A !important;
    color: #64AF8A !important;
}

.status-label {
    text-align: center;
}

.card-sheet2-progress {
    transform: translate(0%, -80%);
    text-align: center;
    width: 100%;
    font-size:xx-small;
}

.action-button-group {
    position: relative;
    z-index: 0;
}

.state-alert {
    font-weight: bold;
    margin-top: 5%;
    margin-left: 20%;
}

.test {
    background-color: aqua;
    color: fuchsia;
    font-size: smaller;
    width: 30%;
}

.book-name {
    font-size: 15px !important;
}

.task-sheet-tasks {
    width: 250px;
    position: relative;
    float: right;
    margin-left: 10px;
}

.btn-sheets {
    margin-left: 5px;
}

.btn-sheets button {
    margin-left: 5px;
}

.btn-tasks {
    height: 30px;
    position: relative;
    float: right;
}

.btn-tasks button {
    margin-left: 5px;
}

.card-collection {
    margin-bottom: 20px;
    padding: 5px 20px 10px 20px;
    background-color:#15112b52 !important;
}

.manage-collection {
    float: right;
    margin-left: 10px;
}

.btn-open-collection {
    float: right;
}

.jobs-automation-spinner-cover {
    opacity: 0.2;
    display: flex;
    position: absolute;
}
.jobs-automation-spinner {
    height: 30%;
    width: 83%;
    position: absolute;
    z-index: 1;
}